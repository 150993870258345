import React from "react"
//import { StaticImage } from 'gatsby-plugin-image'
import { Link, graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />

      <BreadCrumb
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={`${styles.pageTitleHeading} mb-6`}>{title}</h1>
      </div>

      <main className={styles.pageContents}>
        <h3 className={styles.pageContentsHeading3}>◯グリーンイノベーション基金事業の概要、制度全般に関する問い合わせ先</h3>
        <p className="pl-1 mb-5">グリーンイノベーション基金事業統括室<br />E-mail:green-innovation[<sup>*</sup>]nedo.go.jp</p>

        <h3 className={styles.pageContentsHeading3}>◯グリーンイノベーション基金事業の公募内容に関する問い合わせ先</h3>
        <p className="pl-1 mb-5">各<Link className={styles.linkText} to="/about/public-contribution/">公募情報</Link>内の問い合わせ先からご確認ください。</p>
        
        <h3 className={styles.pageContentsHeading3}>◯グリーンイノベーション基金事業の個別プロジェクトの実施内容等に関する問い合わせ先</h3>
        <div className={styles.tableWrapper}>
          <table className={styles.contactTable}>
            <thead>
              <tr>
                <th>個別プロジェクト名称</th>
                <th>担当部署</th>
                <th>E-mail</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.wg1}>
                <td>洋上風力発電の低コスト化</td>
                <td>再生可能エネルギー部</td>
                <td>gi-wind[*]nedo.go.jp</td>
              </tr>
              <tr className={styles.wg1}>
                <td>次世代型太陽電池の開発</td>
                <td>再生可能エネルギー部</td>
                <td>nedo.gi-pv[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg1}>
                <td>廃棄物・資源循環分野におけるカーボンニュートラル実現</td>
                <td>サーキュラーエコノミー部</td>
                <td>gi-wastetox[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg2}>
                <td>大規模水素サプライチェーンの構築</td>
                <td>水素・アンモニア部</td>
                <td>hydrogen[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg2}>
                <td>再エネ等由来の電力を活用した水電解による水素製造</td>
                <td>水素・アンモニア部</td>
                <td>hydrogen[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg2}>
                <td>製鉄プロセスにおける水素活用</td>
                <td>サーキュラーエコノミー部</td>
                <td>gi-steelmaking[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={`${styles.wg2} ${styles.wgParent}`}>
                <td>燃料アンモニアサプライチェーンの構築</td>
                <td></td>
                <td></td>
              </tr>
              <tr className={`${styles.wg2} ${styles.wgChild}`}>
                <td className={styles.indent}>…アンモニア供給関連</td>
                <td>水素・アンモニア部</td>
                <td>hyam-ammonia[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={`${styles.wg2} ${styles.wgLastChild}`}>
                <td className={styles.indent}>…アンモニアの発電利用関連</td>
                <td>サーキュラーエコノミー部</td>
                <td>gi-ammonia[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg2}>
                <td>CO<sub>2</sub>等を用いたプラスチック原料製造技術開発</td>
                <td>バイオ・材料部</td>
                <td>gi_cr_plastic[*]nedo.go.jp</td>
              </tr>
              <tr className={`${styles.wg2} ${styles.wgParent}`}>
                <td>CO<sub>2</sub>等を用いた燃料製造技術開発</td>
                <td></td>
                <td></td>
              </tr>
              <tr className={`${styles.wg2} ${styles.wgChild}`}>
                <td className={styles.indent}>…合成燃料・合成メタン・グリーンLPG製造関連</td>
                <td>サーキュラーエコノミー部</td>
                <td>gi-fuel[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={`${styles.wg2} ${styles.wgChild}`}>
                <td className={styles.indent}>…合成燃料利用関連</td>
                <td>⾃動⾞・蓄電池部</td>
                <td>gi8eng[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={`${styles.wg2} ${styles.wgLastChild}`}>
                <td className={styles.indent}>…持続可能な航空燃料 (SAF)製造関連</td>
                <td>再生可能エネルギー部</td>
                <td>nedo.biofuel[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg2}>
                <td>CO<sub>2</sub>を用いたコンクリート等製造技術開発</td>
                <td>サーキュラーエコノミー部</td>
                <td>gi-concrete[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg2}>
                <td>CO<sub>2</sub>の分離回収等技術開発</td>
                <td>サーキュラーエコノミー部</td>
                <td>gi-capture[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={`${styles.wg3} ${styles.wgParent}`}>
                <td>次世代蓄電池・次世代モーターの開発</td>
                <td></td>
                <td></td>
              </tr>
              <tr className={`${styles.wg3} ${styles.wgChild}`}>
                <td className={styles.indent}>…蓄電池開発関連</td>
                <td>⾃動⾞・蓄電池部</td>
                <td>gi_battery[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={`${styles.wg3} ${styles.wgLastChild}`}>
                <td className={styles.indent}>…モーター関連</td>
                <td>⾃動⾞・蓄電池部</td>
                <td>gi_motor[*]nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>電動車等省エネ化のための車載コンピューティング・シミュレーション技術の開発</td>
                <td>⾃動⾞・蓄電池部</td>
                <td>gi_vehicle_computing[*]nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>スマートモビリティ社会の構築</td>
                <td>⾃動⾞・蓄電池部</td>
                <td>gi-smartmobility[*]nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>次世代デジタルインフラの構築</td>
                <td>半導体・情報インフラ部</td>
                <td>gi-digital[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>次世代航空機の開発</td>
                <td>航空・宇宙部</td>
                <td>gi-aircraft[*]nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>次世代船舶の開発</td>
                <td>水素・アンモニア部</td>
                <td>gi-ship-kobo[*]nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>食料・農林水産業のCO<sub>2</sub>等削減・吸収技術の開発</td>
                <td>フロンティア部</td>
                <td>gi-agri[*]nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>バイオものづくり技術によるCO<sub>2</sub>を直接原料としたカーボンリサイクルの推進</td>
                <td>バイオ・材料部</td>
                <td>gi_bio[*]ml.nedo.go.jp</td>
              </tr>
              <tr className={styles.wg3}>
                <td>製造分野における熱プロセスの脱炭素化</td>
                <td>サーキュラーエコノミー部</td>
                <td>gi-thermal-process[*]ml.nedo.go.jp</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <h3 className={styles.pageContentsHeading3}>○その他、NEDOへのお問い合わせは<a className={styles.linkText} href="https://www.nedo.go.jp/qinf/contact.html" target="_blank" rel="noreferrer noopener">こちら</a>から</h3>
        <p className="pl-1 mb-5">※各ページのリンク先の資料については、経済産業省等それぞれの問い合わせ先にご連絡をお願いします。</p>

        <p className="text-right">E-mailは上記アドレスの[*]を@に変えて使用してください。</p>
      </main>

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`